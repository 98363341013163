import * as dc from 'dc';
import Legend from "./Legend";
import CanvasBarChart from "./CanvasBarChart";
import Easing from "./Easing";

dc["Canvas"] = Object.assign({
    BarChart: CanvasBarChart,
    barChart: (parent, chartGroup) => new dc["Canvas"].BarChart(parent, chartGroup),
}, Easing);
dc["legend"] = () => new Legend();
