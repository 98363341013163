import * as dc from 'dc';
import * as d3 from 'd3';

export default class Legend extends dc.Legend {

    constructor() {
        super();
        this._listeners = d3.dispatch('rendered');
    }

    render() {
        super.render();
        this._listeners.call('rendered', null, this._g);
    }

    on(event, func) {
        this._listeners.on(event, func);
    }

}
