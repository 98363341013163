import * as dc from 'dc';
import * as d3 from 'd3';
import CanvasMixin from "./CanvasMixin";
const DEFAULT_GAP_BETWEEN_BARS = 2;
const DESELECTED_COLOR = "#cccccc"; // TODO: Get dynamically

export default class CanvasBarChart extends CanvasMixin(dc.BarChart) {

    constructor(parent, chartGroup) {
        super(parent, chartGroup);
    }

    legend(legend) {
        if (!legend) return super.legend();
        legend.on('rendered', g => g.style('pointer-events', 'all'));
        return super.legend(legend);
    }

    plotData () {
        this._calculateBarWidth();
        this.data().forEach((data, index) => this._renderBars(index, data));
        if (this.renderLabel()) {
            this._renderLabels(this.data());
        }
        this.drawCanvas();
    }

    legendHighlight(legendData) {
        this.updateShapes(data => {
            return data.type === 0
                ? { alpha: d3.color(data.color).hex() === d3.color(legendData.color).hex() ? 1 : 0.2 }
                : data;
        });
        this.drawCanvas(0);
        super.legendHighlight(legendData);
    }

    legendReset(
        legendData
    ) {
        this.updateShapes(d => {
            return d.type === 0 ? { alpha: 1 } : d;
        });
        this.drawCanvas(0);
        super.legendHighlight(legendData);
    }

    onClick(d) {
        if (this.stack().length > 1) return;
        super.onClick(d);
    }

    initializeData(data) {
        switch (data.type) {
        case 0:
            return { y: this.effectiveHeight(), height: 0 };
        case 1:
            return { y: this.effectiveHeight() };
        }
    }

    listenForMouseEvents() {
        return this.isOrdinal();
    }

    onMouseOverCanvas(x, y, bar) {
        return bar.x <= x && x <= bar.x + bar.width && bar.y <= y && y <= bar.y + bar.height;
    }

    drawShapeOnCanvas(context, data)  {
        switch (data.type) {
        case 0: {
            const x = this._barXPos(data.value);
            const y = this._barYPos(data.value);
            const width = this._barWidth;
            const height = this._barHeight(data.value);
            const color = this._barColorFunction()(data.key, data.value);
            context.globalAlpha = !data.hover ? data.alpha : 0.5;
            context.fillStyle = color;
            context.fillRect(x, y, width, height);
            context.globalAlpha = 1.0;
            break;
        }
        case 1: {
            const text = typeof data.text === 'number' ? Math.round(data.text) : data.text;
            const offset = (data.width - context.measureText(text).width) / 2;
            context.font = "14px Helvetica Neue";
            context.fillStyle = "#000";
            context.fillText(text, data.x + offset, data.y);
            break;
        }
        default:
            break;
        }
    }

    _renderBars (layerIndex, data) {
        for (const value of data.values) {
            const key = this.keyAccessor()(value.data);
            /*this.updateShape(`${layerIndex}-${key}`, {
                type: 0,
                x: this._barXPos(value),
                y: this._barYPos(value),
                width: this._barWidth,
                height: this._barHeight(value),
                color: colorFunc(key, value),
                alpha: 1
            }, value);*/
            this.updateShape(`${layerIndex}-${key}`, {
                type: 0,
                key: key,
                value: value,
                alpha: 1
            }, value);
        }
    }

    _barColorFunction() {
        if (this.isOrdinal()) {
            return this.hasFilter()
                ? (key, d) => this.hasFilter(d.x) ? this.getColor(d) : DESELECTED_COLOR
                : (key, d) => this.getColor(d);
        } else if (this.brushOn() || this.parentBrushOn()) {
            return !this.filter()
                ? (key, d) => this.getColor(d)
                : (key, d) => this.filter().isFiltered(key) ? this.getColor(d) : DESELECTED_COLOR;
        }
    }

    _barXPos(value) {
        return super._barXPos(value) + this.margins().left;
    }

    _barYPos(value) {
        let y = this.y()(value.y + value.y0);
        if (value.y < 0) {
            y -= this._barHeight(value);
        }
        return dc.utils.safeNumber(y) + this.margins().top;
    }

    _renderLabels(data) {
        for (const value of data[data.length - 1].values) {
            const key = this.keyAccessor()(value.data);
            const label = this.label()(value);
            this.updateShape(`text-${key}`, {
                type: 1,
                x: this._barXPos(value),
                y: this._barYPos(value) - DEFAULT_GAP_BETWEEN_BARS,
                width: this._barWidth,
                text: label
            });
        }
    }

}
