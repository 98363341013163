import * as dc from 'dc';
import * as d3 from 'd3';

const CanvasMixin = (Base) => {
    return class extends Base {

        constructor(parent, chartGroup) {
            super(parent, chartGroup);
            this._context = null;
            this._timer = null;
            this._easing = dc.Canvas.easeInQuart;
            this._originalData = {};
            this._currentData = {};
            this._nextData = {};
            this._lastHoverKey = null;
            this._animating = false;
            this._clientX = NaN;
            this._clientY = NaN;
            this._initialized = false;
        }

        easing(easing) {
            if (!arguments.length) {
                return this._easing;
            }
            this._easing = easing;
            return this;
        }

        drawCanvas(
            duration
        ) {
            if (!!this._timer) {
                this._timer.stop();
            }
            if (duration === 0) {
                this._drawShapes(1);
            } else {
                duration = duration || this.transitionDuration() || 500;
                this._animating = true;
                this._timer = d3.timer((elapsed) => {
                    const t = Math.min(elapsed/duration, 1);
                    // console.log('draw timer', t, duration);
                    this._drawShapes(t);
                    if (t === 1) {
                        this._timer.stop();
                        this._animating = false;
                        if (this.listenForMouseEvents()) {
                            this.canvas().dispatchEvent(new Event('mousemove'));
                        }
                    }
                }, 5);
            }
        }

        _drawShapes(t) {
            this.clearCanvas();
            for (const key in this._nextData) {
                this._currentData[key] = this._createInterpolatedData(key, t);
                this.drawShapeOnCanvas(this._context, this._currentData[key]);
            }
        }

        _drawChart(render) {
            if (!this.hasContext()) {
                this.resetCanvas();
            }
            super._drawChart(render);
            this.svg()
                .style('position', 'relative')
                .style('pointer-events', 'none');
        }

        updateShape(key, data, originalData) {
            if (!this._currentData[key]) {
                this._currentData[key] = Object.assign(this.initializeData(data), data);
            }
            this._nextData[key] = {};
            Object
                .keys(data)
                .forEach(prop => {
                    this._nextData[key][prop] = d3.interpolate(this._currentData[key][prop], data[prop]);
                });
            if (originalData !== undefined) {
                this._originalData[key] = originalData;
            }
        }

        updateShapes(callback) {
            for (const key in this._currentData) {
                const data = callback(this._currentData[key]);
                this.updateShape(key, data);
            }
        }

        _createInterpolatedData(key, t) {
            t = this._easing(t);
            const data = Object.assign({}, this._currentData[key]);
            for (const prop in this._nextData[key]) {
                data[prop] = this._nextData[key][prop](t);
            }
            return data;
        }

        resetCanvas() {
            this.select('canvas').remove();

            this.root().style('position', 'relative');
            this.svg()
                .style('position', 'relative')
                .style('pointer-events', 'none');

            const devicePixelRatio = window.devicePixelRatio || 1;

            const margins = this._getMargins();
            const width = parseFloat(this.svg().attr("width")) - margins.right;
            const height = parseFloat(this.svg().attr("height")) - margins.bottom;

            const canvas = this.root()
                .insert('canvas', ':first-child')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', (width) * devicePixelRatio)
                .attr('height', (height) * devicePixelRatio)
                .style('width', `${width}px`)
                .style('height', `${height}px`)
                .style('position', 'absolute')
                .style('pointer-events', 'auto');

            const context = canvas.node().getContext('2d');
            context.scale(devicePixelRatio, devicePixelRatio);
            context.rect(0, 0, width, height);
            context.clip();
            context.imageSmoothingQuality = 'high';
            this._context = context;

            if (this.listenForMouseEvents()) {
                this.canvas().addEventListener('mousemove', this._canvasMouseMove.bind(this));
                this.canvas().addEventListener('mouseenter', this._canvasMouseMove.bind(this));
                this.canvas().addEventListener('mouseleave', this._canvasMouseLeave.bind(this));
                this.canvas().addEventListener('click', this._canvasMouseClick.bind(this));
            }

            this._initialized = true;
        }

        _resize() {
            const margins = this._getMargins();
            const width = parseFloat(this.svg().attr("width")) - margins.right;
            const height = parseFloat(this.svg().attr("height")) - margins.bottom;
            this.root()
                .select('canvas')
                .attr('width', width)
                .attr('height', height)
                .style('width', `${width}px`)
                .style('height', `${height}px`);
        }

        _getMargins() {
            return !this.margins
                ? { top: 0, right: 0, bottom: 0, left: 0 }
                : this.margins();
        }

        _canvasMouseMove(e) {
            this._clientX = e.clientX || this._clientX;
            this._clientY = e.clientY || this._clientY;
            if (this._animating) return;
            const [x, y] = this._getCoordinate(e);
            Object.keys(this._currentData)
                .filter(key => this.onMouseOverCanvas(x, y, this._currentData[key]))
                .forEach(key => {
                    if (!!this._lastHoverKey) {
                        this.updateShape(this._lastHoverKey, { hover: false });
                    }
                    this.updateShape(key, { hover: true });
                    this.drawCanvas(0);
                    this._lastHoverKey = [key];
                });
        }

        _canvasMouseLeave() {
            this._clientX = NaN;
            this._clientY = NaN;
            if (!!this._lastHoverKey) {
                this.updateShape(this._lastHoverKey, { hover: false });
            }
            this.drawCanvas(0);
        }

        _canvasMouseClick(e) {
            const [x, y] = this._getCoordinate(e);
            Object.keys(this._currentData)
                .filter(key => this.onMouseOverCanvas(x, y, this._currentData[key]))
                .forEach(key => this.onClick(this._originalData[key]));
        }

        _getCoordinate(e) {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            return [x, y];
        }

        clearCanvas() {
            this.context().clearRect(0, 0, (this.canvas().width + 2), (this.canvas().height + 2));
        }

        context() {
            return this._context;
        }

        canvas() {
            return this._context.canvas;
        }

        hasContext() {
            return !!this._context;
        }

        initializeData() {
            throw new Error("Not implemented");
        }

        drawShapeOnCanvas() {
            throw new Error("Not implemented");
        }

        onMouseOverCanvas() {
            return null;
        }

        listenForMouseEvents() {
            return false;
        }

        render() {
            const ret = super.render();
            this._resize();
            return ret;
        }

    };
};

export default CanvasMixin;
